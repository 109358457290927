<template>
  <div>
    <b-row>
      <b-col
        v-for="item in dataList"
        :key="item.id"
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-form-checkbox
            v-model="dataItem.segment_services"
            :value="item.id"
            multiple
          >
            <div class="font-weight-bold">
              {{ item.title }}
            </div>
            <div class="font-small-2 text-warning">
              {{ item.service_category }}
            </div>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  name: 'Services',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
  },
  computed: {
    dataItem() {
      return this.$store.getters['segments/dataItem']
    },
    dataList() {
      return this.$store.getters['services/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('services/getDataList')
    },
  },
}
</script>
