<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>Genel Bilgi</span>
        </template>
        <b-card>
          <title-text />
          <summary-text />
          <content-text />
          <ordering />
        </b-card>
        <b-row>
          <b-col>
            <icon-card />
          </b-col>
          <b-col>
            <image-card />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :disabled="!dataItem.id">
        <template #title>
          <feather-icon icon="BoxIcon" />
          <span>Hizmetler</span>
        </template>
        <b-card>
          <services-card />
        </b-card>
      </b-tab>
      <b-tab
        :disabled="!dataItem.id"
        lazy
      >
        <template #title>
          <FeatherIcon icon="SettingsIcon" />
          SEO Ayarları
        </template>
        <seo-form
          :item-id="dataItem.id"
          id-components="14"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTabs, BTab,
} from 'bootstrap-vue'

import TitleText from '@/views/Admin/Segments/elements/Title.vue'
import SummaryText from '@/views/Admin/Segments/elements/Summary.vue'
import ContentText from '@/views/Admin/Segments/elements/Content.vue'
import ImageCard from '@/views/Admin/Segments/elements/Image.vue'
import IconCard from '@/views/Admin/Segments/elements/IconCard.vue'
import Ordering from '@/views/Admin/Segments/elements/Ordering.vue'
import ServicesCard from '@/views/Admin/Segments/elements/Services.vue'
import SeoForm from '@/views/Admin/Seo_configs/ItemForm.vue'

export default {
  name: 'ItemForm',
  components: {
    SeoForm,
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    TitleText,
    SummaryText,
    ContentText,
    ImageCard,
    IconCard,
    Ordering,
    ServicesCard,
  },
  computed: {
    dataItem() {
      return this.$store.getters['segments/dataItem']
    },
  },
}
</script>
